.p-picklist-buttons {
  gap: 8px;
}

.p-picklist .p-picklist-buttons .p-button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.62px;
  height: 32px;
  border-radius: 4px;
  box-shadow: none;
  padding: 0 12px;

  color: var(--text-primary);
  border: 2px solid var(--primary-500);
  background-color: var(--primary-500);
}

.p-picklist .p-picklist-header {
  padding: 7px 18px;
}

.p-picklist .p-picklist-filter-container {
  padding: 10px;
}

.p-picklist .p-picklist-list {
  padding: 0;
  height: 40px;
}

.p-picklist .p-picklist-list .p-picklist-item {
  border-bottom: 1px solid var(--surface-border);
}

/* Cor do fundo do item selecionado */
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background: var(--bluegray-500);

  p,
  .text-secondary {
    color: var(--text-primary) !important;
  }
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  box-shadow: inset 0 0 0 0.15rem var(--focus);
}

/* Título dos blocos */
.p-picklist .p-picklist-header .p-picklist-title {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
}
