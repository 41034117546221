.shepherd-element {
  max-width: 500px !important;
}

/* header */
.shepherd-has-title .shepherd-content .shepherd-header {
  background: var(--surface-card) !important;
  padding: 24px 24px 0 24px !important;
}

/* content */
.shepherd-text {
  padding: 24px !important;
}

/* footer */
.shepherd-footer {
  padding: 0 24px 24px !important;
}

/*  buttons  */
.shepherd-button.p-button-raised {
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  height: 32px;
  min-width: 42px;
  border-radius: 4px;
  box-shadow: none;

  &.primary {
    color: var(--text-primary);
    border: 2px solid var(--primary-500);
    background-color: var(--primary-500);
  }

  &.secondary {
    color: var(--text-primary);
    border: 2px solid var(--bluegray-300);
    background-color: var(--bluegray-300);
  }

  &:enabled:hover {
    &.primary {
      color: var(--text-primary);
      border: 2px solid var(--primary-400);
      background: var(--primary-400);
    }

    &.secondary {
      color: var(--text-primary);
      background: var(--bluegray-400);
      border: 2px solid var(--bluegray-400);
    }
  }

  &:enabled:active {
    &.primary {
      color: var(--text-primary);
      background: var(--primary-700);
      border: 2px solid var(--primary-700);
    }

    &.secondary {
      color: var(--text-primary);
      background: var(--bluegray-600);
      border: 2px solid var(--bluegray-600);
    }
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);
    color: var(--text-primary);

    &.primary {
      background: var(--primary-500);
      border: 2px solid var(--primary-500);
    }

    &.secondary {
      background: var(--bluegray-300);
      border: 2px solid var(--bluegray-300);
    }
  }
}

.shepherd-button.p-button-text {
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  height: 32px;
  min-width: 42px;
  border-radius: 4px;

  &.primary {
    color: var(--primary-500);
    background-color: transparent;
  }

  &.secondary {
    color: var(--bluegray-500);
    background-color: transparent;
  }

  &:enabled:hover {
    &.primary {
      background: rgba(52, 39, 205, 0.04);
      color: var(--primary-500);
    }

    &.secondary {
      background: rgba(100, 116, 139, 0.04);
      color: var(--bluegray-500);
    }
  }

  &:enabled:active {
    &.primary {
      background: rgba(52, 39, 205, 0.16);
      color: var(--primary-500);
    }

    &.secondary {
      background: rgba(100, 116, 139, 0.16);
      color: var(--bluegray-500);
    }
  }

  &:enabled:focus {
    box-shadow: 0px 0px 0px 3.2px var(--focus);

    &.primary {
      background: rgba(52, 39, 205, 0.16);
      color: var(--primary-500);
    }

    &.secondary {
      background: rgba(100, 116, 139, 0.16);
      color: var(--bluegray-500);
    }
  }
}
