.ng-select.custom .ng-select-container:hover {
  transition: 300ms;
  border-color: var(--primary-500);
}

.ng-select.ng-select-disabled > .ng-select-container:hover {
  transition: none;
  border-color: var(--input-border);
  box-shadow: none;
}

.ng-select.ng-select-disabled {
  opacity: 0.6;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--primary-500);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 0 3px rgb(108 65 156 / 10%);
}

.ng-select.custom .ng-select-container .ng-dropdown-panel {
  background-color: var(--focus);
}

.ng-select .ng-select-container {
  border-color: var(--input-border);
}

.ng-select div,
.ng-select input .ng-select span {
  font-weight: 400;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: #e9ecef;
  transition: 300ms;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--highlight-bg);

  div {
    div {
      font-weight: 600;
    }
  }
}

.ng-select.custom .ng-select-container:focus {
  border-color: var(--primary-500);
}

.ng-select.custom .ng-select-container {
  min-height: 0px;
  border-radius: 3px;
  height: 32.5px;
  font-size: 14px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  font-size: 14px;
  line-height: 100%;
  height: auto;
  max-height: 240px;
}

.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: visible;
  height: 32.5px;
  display: flex;
  align-items: center;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--bluegray-200);
  font-size: 14px;
}

.flex-select {
  flex: 1;
  width: 100%;
}

// Style referente ao ícone de lupa adicioando aos ng-selects que possuem a classe search-icon
::ng-deep .ng-select.search-icon.ng-select-opened .ng-arrow:before {
  font-family: "PrimeIcons";
  content: "\e908";
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-select {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    height: auto;
    max-height: 136px !important;
  }
}

.openNgSelect {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 120px;
  }
}

@media only screen and (max-height: 680px) {
  .custom-select {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 120px;
    }
  }
}
