.p-overlaypanel {
  &.overlaypanel-validation {
    width: 380px;
    border-left: 6px solid var(--red-500);
  }

  &.overlaypanel-log {
    width: 240px;
    padding: 20px;

    .p-overlaypanel-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  &.overlaypanel-context {
    padding: 0;

    .p-overlaypanel-content {
      padding: 5px;
      display: flex;
      width: 100%;
      gap: 4px;
    }
  }

  &.overlay-calendar {
    width: 303px;
  }
}

.p-overlaypanel .p-overlaypanel-content {
  // padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

.p-overlaypanel .p-overlaypanel-close {
  background: var(--primary-500) !important;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.overlaypanel-section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 324px;
    overflow-y: auto;

    .fields {
      display: flex;
      flex-direction: column;
      padding-top: 8px;

      &__line {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        .pi {
          font-size: 6px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
