@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// load utilizado nos summaries
.cards-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    animation: spin 0.5s linear;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
