.p-inplace .p-inplace-display {
  border-radius: 6px;
  padding: 0.5rem 0;
}

.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--focus);
}
