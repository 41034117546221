.p-timeline-horizontal .p-timeline-event:last-child {
  flex: 1;
  overflow-x: auto;
  cursor: grab;
  display: flex;
  width: 100%;
}

.p-timeline-horizontal {
  width: 100%;
}

.p-timeline-horizontal .p-timeline-event {
  // max-width: max-content;
  min-width: 160px;
}

.p-timeline-left .p-timeline-event-opposite {
  text-align: left !important;
}

.p-timeline-event .p-timeline-event-content {
  height: 40px !important;
}
