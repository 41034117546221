.grid-content {
  background: var(--surface-card);
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 24px;
}

.grid-0-25 {
  width: 100%;
  grid-column: 1/2;
}

.grid-0-50 {
  width: 100%;
  grid-column: 1/3;
}

.grid-0-75 {
  width: 100%;
  grid-column: 1/4;
}

.grid-0-100 {
  width: 100%;
  grid-column: 1/5;
}

.grid-25-50 {
  width: 100%;
  grid-column: 2/3;
}

.grid-25-75 {
  width: 100%;
  grid-column: 2/4;
}

.grid-25-100 {
  width: 100%;
  grid-column: 2/5;
}

.grid-50-75 {
  width: 100%;
  grid-column: 3/4;
}

.grid-50-100 {
  width: 100%;
  grid-column: 3/5;
}

.grid-75-100 {
  width: 100%;
  grid-column: 4/5;
}

.field {
  margin: 0;
}

@media screen and (max-width: 576px) {
  .grid-form {
    .grid-0-25 {
      grid-column: 1/5;
    }

    .grid-0-50 {
      grid-column: 1/5;
    }

    .grid-0-75 {
      grid-column: 1/5;
    }

    .grid-0-100 {
      grid-column: 1/5;
    }

    .grid-25-50 {
      grid-column: 1/5;
    }

    .grid-25-75 {
      grid-column: 1/5;
    }

    .grid-25-100 {
      grid-column: 1/5;
    }

    .grid-50-75 {
      grid-column: 1/5;
    }

    .grid-50-100 {
      grid-column: 1/5;
    }

    .grid-75-100 {
      grid-column: 1/5;
    }
  }
}
