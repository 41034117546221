.p-tag {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8;

  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 100%;
  height: 24px;
}

.tag-default {
  color: var(--text-primary);

  &-primary {
    background: var(--primary-500);
  }

  &-custom {
    background: var(--bluegray-400);
  }

  &-error {
    background: var(--red-500);
  }

  &-info {
    background: var(--blue-500);
  }

  &-success {
    background: var(--green-500);
  }

  &-warning {
    background: var(--yellow-500);
    color: var(--yellow-800);
  }
}

.tag-faded {
  &-custom {
    background: var(--bluegray-100);
    color: var(--bluegray-800);
  }

  &-dash-custom {
    background: transparent;
    color: var(--bluegray-800);
    border: 1px solid var(--bluegray-800);
  }

  &-error {
    background: var(--red-100);
    color: var(--red-800);
  }

  &-info {
    background: var(--blue-100);
    color: var(--blue-800);
    border: 1px solid var(--blue-500);
  }

  &-success {
    background: var(--green-100);
    color: var(--green-800);
  }

  &-warning {
    background: var(--yellow-100);
    color: var(--yellow-800);
  }
}

.tag-outline {
  background: transparent;

  &-custom {
    color: var(--bluegray-500);
    border: 1px solid var(--bluegray-500);
  }

  &-error {
    color: var(--red-500);
  }

  &-info {
    color: var(--blue-500);
  }

  &-success {
    color: var(--green-500);
  }

  &-warning {
    color: var(--yellow-500);
  }
}

.round-sm {
  border-radius: 4px;
}

.round-lg {
  border-radius: 24px;
  padding: 4px 8px;
}
