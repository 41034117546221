.item-success {
  color: var(--green-500);
}

.item-error {
  color: var(--red-500);
}

:host ::ng-deep .p-password {
  ul {
    list-style-type: none;
  }
}
