.p-orderlist .p-orderlist-controls {
  display: none;
}

.p-orderlist .p-orderlist-filter-container {
  padding: 7px 14px;
  background-color: var(--surface-b);
}

.p-orderlist .p-orderlist-header {
  display: flex;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 3px 12px;
  height: 40px;
  align-items: center;
}

.p-orderlist .p-orderlist-list {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: var(--text-color);
  background: var(--highlight-bg);
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  box-shadow: none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0;
}
