/*  titulo da tabela  */
.p-datatable .p-datatable-header {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 12px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-datatable-thead {
  .p-dropdown-label {
    display: flex;
    align-items: center;
  }
}

/*  titulo da coluna  */
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 6px 14px;
}

.p-datatable.p-datatable-sm
  .p-datatable-thead
  > tr:not(.background-head-filter)
  > th {
  height: 40px;

  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  padding: 14px;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: var(--highlight-bg);
  color: var(--primary-600);
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight {
  background: var(--highlight-bg) !important;
  color: var(--primary-600) !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-600) !important;
}

.p-dropdown-label,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var(--highlight-bg);
}

/*  linha da tabela  */
.p-datatable .p-datatable-tbody > tr > td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 6px 14px;
  height: 32px;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--bluegray-500);
  color: var(--text-primary);

  .pi {
    color: var(--text-primary) !important;
  }

  .valid-date {
    background: var(--green-200);
    color: var(--green-800);

    .pi {
      color: var(--green-800) !important;
    }
  }

  .invalid-date {
    background: var(--orange-200);
    color: var(--orange-800);

    .pi {
      color: var(--orange-800) !important;
    }
  }

  .no-date {
    background: var(--yellow-200);
    color: var(--yellow-800);

    .pi {
      color: var(--yellow-800) !important;
    }
  }
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
  outline-offset: 0.15rem;
}

/*  paginação  */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--surface-c);
  border-color: var(--surface-c);
  border-radius: 21px;
  font-size: 14px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 21px;
  font-size: 14px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  border-radius: 21px;
  font-size: 14px;
}

.p-paginator .p-paginator-current {
  font-size: 14px;
}

.p-paginator {
  justify-content: flex-end !important;
  border-color: var(--surface-border);
  border-style: solid;
  border-width: thin;
  border-top: none;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.col-with-flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
