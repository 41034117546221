/* P-PANEL */
.p-panel .p-panel-header {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 3px 12px;
  height: 40px;
}

.p-panel .p-panel-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panel .p-panel-header .p-panel-header-icon {
  border-radius: 50%;
}

/* P-SCROLLPANEL */
.p-scrollpanel {
  &.scrollpanel-custom {
    .p-scrollpanel-wrapper {
      background-color: transparent;
    }

    .p-scrollpanel-bar {
      background-color: var(--surface-card);
      border-radius: 8px;
    }

    &:hover {
      .p-scrollpanel-bar {
        background-color: var(--surface-card);
        transition: background-color 0.2s;

        &:hover {
          background-color: var(--primary-500);
        }

        &:active {
          background-color: var(--primary-500);
        }
      }
    }
  }

  &.scrollpanel-custom-static {
    .p-scrollpanel-bar {
      background-color: var(--surface-card);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--primary-500);
      }

      &:active {
        background-color: var(--primary-500);
      }
    }
  }
}

/* TABVIEW */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  background: var(--surface-a);
  border-color: var(--primary-600);
  color: var(--primary-600);

  .ball {
    background-color: var(--primary-0);
    color: var(--primary-500);
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--text-color-secondary);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: 0px 0px 0px 3.2px var(--focus);
}

:host ::ng-deep .p-tabview .p-tabview-panels {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-tabview-panels {
  padding: 0;
}

.p-tabview .p-tabview-nav {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

/* ACCORDION */

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 3.2px var(--focus);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 12px;
}

/* TOOLBAR */
.p-toolbar {
  padding: 12px;
  background-color: var(--surface-card);
  border-radius: 4px;
}

.separator-custom {
  border-right: 1px solid var(--surface-border);
  height: 32px;
}
