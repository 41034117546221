.message-icon {
  &__info {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 48px;
    background: var(--blue-50);

    .pi {
      color: var(--blue-600);
    }
  }

  &__success {
    background: var(--green-50);

    .pi {
      color: var(--green-600);
    }
  }

  &__warning {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 48px;
    background: var(--yellow-50);

    .pi {
      color: var(--yellow-600);
    }
  }

  &__error {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 48px;
    background: var(--red-50);

    .pi {
      color: var(--red-600);
    }
  }
}
