.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: var(--text-color);
  font-size: 14px;
}

.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
  text-decoration: none !important;
  color: var(--text-color-secondary);

  cursor: pointer;
}

.div-content {
  .pi-chevron-right:before {
    color: var(--text-color);
    font-size: 12px;
  }
}

.p-breadcrumb {
  background: transparent;
  border: none;
  padding: 0;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator .p-icon {
  color: var(--text-color-secondary);
  width: 0.875rem;
}
