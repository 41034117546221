.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component,
.flex > .p-inputwrapper {
  height: 32px;
}

.p-inputtext {
  height: 32px;
  font-size: 14px;

  &:enabled:hover {
    border-color: var(--primary-500);
  }

  &:enabled:active {
    border-color: var(--primary-500);
  }

  &:enabled:focus {
    border-color: var(--primary-500);
    box-shadow: 0px 0px 0px 3.2px var(--focus);
  }
}

/*  p-column-filter  */
.p-column-filter {
  width: 100%;
}

/*  input with button  */
.input-calendar {
  margin-bottom: 0;

  .p-button-icon-only {
    color: var(--bluegray-500) !important;
    background-color: var(--surface-overlay);
    border-left: 0px solid var(--input-border);
    border-top: 1px solid var(--input-border);
    border-right: 1px solid var(--input-border);
    border-bottom: 1px solid var(--input-border);
    // height: 30px;

    &:hover {
      border-left: 1px solid var(--primary-500);
      border-top: 1px solid var(--primary-500);
      border-right: 1px solid var(--primary-500);
      border-bottom: 1px solid var(--primary-500);
      background: rgba(100, 116, 139, 0.04);
    }

    &:focus {
      box-shadow: 0px 0px 0px 3.2px var(--focus);
    }
  }

  .p-inputtext {
    height: 32px;
  }
}

.p-calendar-w-btn .p-datepicker-trigger {
  height: 32px;
}

/*  autocomplete   */
.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  height: 32px;
  font-size: 14px;
}

.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
  padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.5rem 1rem;
}

.p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: var(--red-500) !important;
}

/* CHECKBOX */
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-500);
  background: var(--primary-500);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary-500);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--primary-500);
  background: var(--primary-500);
  color: var(--text-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0px 0px 0px 3.2px var(--focus);
  border-color: var(--primary-500);
}

.p-checkbox-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-color-secondary);
}

.p-checkbox-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
  pointer-events: none;
}

/* dropdown */
.p-dropdown {
  width: 100%;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-500);
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 3.2px var(--focus);
  border-color: var(--primary-500);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--focus);
}

.p-dropdown-label {
  display: flex !important;
  height: 32px;
  align-items: center;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  color: var(--text-color);
  font-size: 14px;
}

input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  font-style: normal;
  color: var(--bluegray-200);
}

/*  p-multiSelect  */
.p-multiselect,
.p-multiselect-label {
  font-size: 14px;
  line-height: 100%;
  color: var(--text-color-secondary);
  height: 32px;
}

.p-multiselect {
  &.p-focus {
    box-shadow: 0 0 0 0.2rem var(--focus) !important;
    border-color: var(--primary-500) !important;
  }
}

.p-multiselect:not(.p-disabled):hover {
  border-color: var(--primary-500);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--text-color);
  background: transparent;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem var(--focus);
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

:host ::ng-deep .p-multiselect:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--focus) !important;
}

.p-multiselect-item {
  font-size: 14px;
  line-height: 100%;
  color: var(--text-color-secondary);
}

/*  p-inputSwitch  */
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: var(--primary-500);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-500);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem var(--focus);
}

.switch-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.switch-label {
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    padding-right: 8px;
  }
}

/*  p-chips  */
.p-chips {
  display: flex !important;
}

.p-chips .p-chips-multiple-container {
  width: 100%;
  padding: 4px 4px;
  height: max-content;
  align-items: center;
  gap: 2px;
  display: flex;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: var(--primary-500);
}

.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
  box-shadow: 0 0 0 0.2rem var(--focus);
  border-color: var(--primary-500);
}

.p-chips .p-chips-multiple-container .p-chips-token {
  margin-right: 0;
  background: var(--primary-300);
  color: var(--gray-50);
  border-radius: 3px;
  padding: 1px 8px;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-size: 14px;
  line-height: 100%;
  color: var(--text-color);
}

/*  textarea  */
textarea {
  font-size: 14px;
  line-height: 100%;
  color: var(--text-color);
  padding: 7px;
  border: 1px solid var(--input-border);
  border-radius: 4px;

  &:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0px 0px 0px 3.2px var(--focus);
    border-color: var(--primary-500);
  }
}

.p-fluid .p-inputtext {
  width: 100%;
}

/*  'a' inside the ng-select  */
.link-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  gap: 4px;
  color: var(--primary-500);
  text-decoration: none;
  font-size: 14px;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;

  i {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: var(--primary-0);
    color: var(--primary-500);
  }
}

.ng-dropdown-panel .ng-dropdown-footer {
  padding: 0;
}

/*  inputgroup-addon  */
.p-inputgroup-addon {
  height: 32px;
  font-size: 14px;
}

.text-overflow-ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: "...";
  white-space: nowrap;
  position: relative;
  width: 100%;
  line-height: 125%;
}
